<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Gestión de Informe de Aseguramientos de calidad</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(registrarIso9001)">
                            <b-row>
                                <b-col md="12" class="mb-2 ">
                                    <span class="h5"> Datos</span>
                                </b-col>
                                <b-col md="3">
                                    <validation-provider name="tipo" :rules="{ required: true }" v-slot="validationContext">
                                        <b-form-group label="Tipo:" class="mb-2">
                                            <b-form-select :options="comboTipo" @change="cambiarCombo()" v-model.lazy="datosIso9001.tipo" :state="getValidationState(validationContext)">
                                                <template v-slot:first>
                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="3" v-if="datosIso9001.tipo==1">
                                    <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                                        <b-form-group label="Proyecto:" class="mb-2">
                                            <v-select :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIso9001.idProyectoLote , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosIso9001.idProyectoLote" :options="comboProyecto" @search:blur="blurProyecto">
                                                <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Lista de Proyectos'}"> Registra aquí</router-link></span>
                                            </v-select>
                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="3" v-if="datosIso9001.tipo==2">
                                    <validation-provider name="lote_producto" rules="required" v-slot="{errors}">
                                        <b-form-group label="Lote de producto:" class="mb-2">
                                            <v-select :reduce="comboLoteProductos =>comboLoteProductos.idLoteProducto" label="codigo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIso9001.idProyectoLote , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosIso9001.idProyectoLote" :options="comboLoteProductos" @search:blur="blurLoteProducto">
                                                <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Lote de productos'}"> Registra aquí</router-link></span>
                                            </v-select>
                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="3">
                                    <validation-provider name="servicio" rules="required" v-slot="{errors}">
                                        <b-form-group label="Servicio:" class="mb-2">
                                            <v-select :reduce="comboServicios =>comboServicios.idServicio" label="servicio" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIso9001.idServicio , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosIso9001.idServicio" :options="comboServicios" @search:blur="blurServicio">
                                                <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Servicios coordinador'}"> Registra aquí</router-link></span>
                                            </v-select>
                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="3">
                                    <validation-provider name="ejercicio" rules="required" v-slot="{errors}">
                                        <b-form-group label="Ejercicio/Año:" class="mb-2">
                                            <v-select taggable :reduce="comboEjercicioAnio =>comboEjercicioAnio.descripcion" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIso9001.anio , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosIso9001.anio" :options="comboEjercicioAnio" @search:blur="blurEjercicio">
                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                            </v-select>
                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                        </b-form-group>
                                    </validation-provider>

                                </b-col>
                                <!-- <b-col md="3"></b-col> -->
                                <b-col md="12" class="mt-3">
                                    <div class="accordion" role="tablist">
                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-1 class="bg-accordion">
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                    <span class="h6"> Descripción para los productos y servicios </span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="descripcion para los productos y servicios" :rules="{ }" v-slot="validationContext">
                                                                        <b-form-group label="Descripcion para los productos y servicios:" class="mb-2">
                                                                            <b-form-textarea rows="3" max-rows="6" v-model.lazy="datosIso9001.listaPuntosNorma[0].descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripcion"></b-form-textarea>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="responsable descripcion" v-slot="validationContext">
                                                                        <b-form-group label="Responsable:" class="mb-2">
                                                                            <b-form-input v-model.lazy="datosIso9001.listaPuntosNorma[0].responsable" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha toma de muestra" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha toma de muestra:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[0].fechaTomaMuestra" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha informe" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha informe:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[0].fechaInforme" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="12">
                                                            <CButton size="sm" class="float-right" color="dark" @click="agregarDocumento(1)">
                                                                <i class="fas fa-plus fa-sm"></i> Agregar documento
                                                            </CButton>
                                                        </b-col>
                                                        <b-col md="12" class="mt-3">
                                                            <b-table :items="datosIso9001.listaPuntosNorma[0].listaDocumentos" :fields="cabeceraDocumentos" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                <template v-slot:cell(codigoDocumento)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese código" v-model.lazy="row.item.codigoDocumento" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(nombre)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese nombre" v-model.lazy="row.item.nombre" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(fechaPublicacion)="row">
                                                                    <b-form-input type="date" v-model.lazy="row.item.fechaPublicacion"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(responsable)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese responsable" v-model.lazy="row.item.responsable" size="md"></b-form-input>
                                                                </template>
                                                                <template #cell(documento)="row">
                                                                    <b-form-group>
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="row.item.urlDocumento != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumento)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file v-model.lazy="row.item.documento" class="text-left" :placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </template>
                                                                <template #cell(opciones)="param">
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc==''" @click="quitarDocumento(param, 1)" v-show="datosIso9001.listaPuntosNorma[0].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Quitar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc!=''" @click="eliminarDocumento(param, 1)" v-show="datosIso9001.listaPuntosNorma[0].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                </template>
                                                            </b-table>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-2 class="bg-accordion">
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                    <span class="h6"> Diseño y desarrollo de los productos y servicios </span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="diseño y desarrollo de los productos y servicios" :rules="{ }" v-slot="validationContext">
                                                                        <b-form-group label="Diseño y desarrollo de los productos y servicios:" class="mb-2">
                                                                            <b-form-textarea rows="3" max-rows="6" v-model.lazy="datosIso9001.listaPuntosNorma[1].descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese el diseño y desarrollo"></b-form-textarea>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="responsable diseño y desarrollo" v-slot="validationContext">
                                                                        <b-form-group label="Responsable:" class="mb-2">
                                                                            <b-form-input v-model.lazy="datosIso9001.listaPuntosNorma[1].responsable" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha toma de muestra" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha toma de muestra:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[1].fechaTomaMuestra" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha informe" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha informe:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[1].fechaInforme" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="12">
                                                            <CButton size="sm" class="float-right" color="dark" @click="agregarDocumento(2)">
                                                                <i class="fas fa-plus fa-sm"></i> Agregar documento
                                                            </CButton>
                                                        </b-col>
                                                        <b-col md="12" class="mt-3">
                                                            <b-table :items="datosIso9001.listaPuntosNorma[1].listaDocumentos" :fields="cabeceraDocumentos" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                <template v-slot:cell(codigoDocumento)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese código" v-model.lazy="row.item.codigoDocumento" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(nombre)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese nombre" v-model.lazy="row.item.nombre" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(fechaPublicacion)="row">
                                                                    <b-form-input type="date" v-model.lazy="row.item.fechaPublicacion"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(responsable)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese responsable" v-model.lazy="row.item.responsable" size="md"></b-form-input>
                                                                </template>
                                                                <template #cell(documento)="row">
                                                                    <b-form-group>
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="row.item.urlDocumento != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumento)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file v-model.lazy="row.item.documento" class="text-left" :placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </template>
                                                                <template #cell(opciones)="param">
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc==''" @click="quitarDocumento(param, 2)" v-show="datosIso9001.listaPuntosNorma[1].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Quitar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc!=''" @click="eliminarDocumento(param, 2)" v-show="datosIso9001.listaPuntosNorma[1].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                </template>
                                                            </b-table>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-3 class="bg-accordion">
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                    <span class="h6"> Producción y provisión del servicio </span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="producción y provisión del servicios" :rules="{ }" v-slot="validationContext">
                                                                        <b-form-group label="Producción y provisión del servicios:" class="mb-2">
                                                                            <b-form-textarea rows="3" max-rows="6" v-model.lazy="datosIso9001.listaPuntosNorma[2].descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la producción y provisión"></b-form-textarea>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="responsable producción y provisión" v-slot="validationContext">
                                                                        <b-form-group label="Responsable:" class="mb-2">
                                                                            <b-form-input v-model.lazy="datosIso9001.listaPuntosNorma[2].responsable" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha toma de muestra" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha toma de muestra:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[2].fechaTomaMuestra" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha informe" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha informe:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[2].fechaInforme" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="12">
                                                            <CButton size="sm" class="float-right" color="dark" @click="agregarDocumento(3)">
                                                                <i class="fas fa-plus fa-sm"></i> Agregar documento
                                                            </CButton>
                                                        </b-col>
                                                        <b-col md="12" class="mt-3">
                                                            <b-table :items="datosIso9001.listaPuntosNorma[2].listaDocumentos" :fields="cabeceraDocumentos" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                <template v-slot:cell(codigoDocumento)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese código" v-model.lazy="row.item.codigoDocumento" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(nombre)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese nombre" v-model.lazy="row.item.nombre" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(fechaPublicacion)="row">
                                                                    <b-form-input type="date" v-model.lazy="row.item.fechaPublicacion"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(responsable)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese responsable" v-model.lazy="row.item.responsable" size="md"></b-form-input>
                                                                </template>
                                                                <template #cell(documento)="row">
                                                                    <b-form-group>
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="row.item.urlDocumento != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumento)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file v-model.lazy="row.item.documento" class="text-left" :placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </template>
                                                                <template #cell(opciones)="param">
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc==''" @click="quitarDocumento(param, 3)" v-show="datosIso9001.listaPuntosNorma[2].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Quitar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc!=''" @click="eliminarDocumento(param, 3)" v-show="datosIso9001.listaPuntosNorma[2].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                </template>
                                                            </b-table>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-4 class="bg-accordion">
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                    <span class="h6"> Liberación de los productos y servicios </span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="liberación de los productos y servicios" :rules="{ }" v-slot="validationContext">
                                                                        <b-form-group label="Liberación de los productos y servicios:" class="mb-2">
                                                                            <b-form-textarea rows="3" max-rows="6" v-model.lazy="datosIso9001.listaPuntosNorma[3].descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la liberación"></b-form-textarea>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="responsable liberación" v-slot="validationContext">
                                                                        <b-form-group label="Responsable:" class="mb-2">
                                                                            <b-form-input v-model.lazy="datosIso9001.listaPuntosNorma[3].responsable" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha toma de muestra" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha toma de muestra:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[3].fechaTomaMuestra" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha informe" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha informe:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[3].fechaInforme" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="12">
                                                            <CButton size="sm" class="float-right" color="dark" @click="agregarDocumento(4)">
                                                                <i class="fas fa-plus fa-sm"></i> Agregar documento
                                                            </CButton>
                                                        </b-col>
                                                        <b-col md="12" class="mt-3">
                                                            <b-table :items="datosIso9001.listaPuntosNorma[3].listaDocumentos" :fields="cabeceraDocumentos" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                <template v-slot:cell(codigoDocumento)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese código" v-model.lazy="row.item.codigoDocumento" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(nombre)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese nombre" v-model.lazy="row.item.nombre" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(fechaPublicacion)="row">
                                                                    <b-form-input type="date" v-model.lazy="row.item.fechaPublicacion"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(responsable)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese responsable" v-model.lazy="row.item.responsable" size="md"></b-form-input>
                                                                </template>
                                                                <template #cell(documento)="row">
                                                                    <b-form-group>
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="row.item.urlDocumento != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumento)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file v-model.lazy="row.item.documento" class="text-left" :placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </template>
                                                                <template #cell(opciones)="param">
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc==''" @click="quitarDocumento(param, 4)" v-show="datosIso9001.listaPuntosNorma[3].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Quitar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc!=''" @click="eliminarDocumento(param, 4)" v-show="datosIso9001.listaPuntosNorma[3].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                </template>
                                                            </b-table>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle.accordion-5 class="bg-accordion">
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                    <span class="h6"> Control de las salidas no conformes </span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="control de las salidas no conformes" :rules="{ }" v-slot="validationContext">
                                                                        <b-form-group label="Control de las salidas no conformes:" class="mb-2">
                                                                            <b-form-textarea rows="3" max-rows="6" v-model.lazy="datosIso9001.listaPuntosNorma[4].descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese el control de las salidas no conformes"></b-form-textarea>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="responsable control de las salidas" v-slot="validationContext">
                                                                        <b-form-group label="Responsable:" class="mb-2">
                                                                            <b-form-input v-model.lazy="datosIso9001.listaPuntosNorma[4].responsable" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="5">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha toma de muestra" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha toma de muestra:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[4].fechaTomaMuestra" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="fecha informe" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Fecha informe:" class="mb-2">
                                                                            <b-form-input type="date" v-model.lazy="datosIso9001.listaPuntosNorma[4].fechaInforme" :state="getValidationState(validationContext)"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="1"></b-col>
                                                        <b-col md="12">
                                                            <CButton size="sm" class="float-right" color="dark" @click="agregarDocumento(5)">
                                                                <i class="fas fa-plus fa-sm"></i> Agregar documento
                                                            </CButton>
                                                        </b-col>
                                                        <b-col md="12" class="mt-3">
                                                            <b-table :items="datosIso9001.listaPuntosNorma[4].listaDocumentos" :fields="cabeceraDocumentos" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                <template v-slot:cell(codigoDocumento)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese código" v-model.lazy="row.item.codigoDocumento" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(nombre)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese nombre" v-model.lazy="row.item.nombre" size="md"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(fechaPublicacion)="row">
                                                                    <b-form-input type="date" v-model.lazy="row.item.fechaPublicacion"></b-form-input>
                                                                </template>
                                                                <template v-slot:cell(responsable)="row">
                                                                    <b-form-input type="text" placeholder="Ingrese responsable" v-model.lazy="row.item.responsable" size="md"></b-form-input>
                                                                </template>
                                                                <template #cell(documento)="row">
                                                                    <b-form-group>
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="row.item.urlDocumento != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumento)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file v-model.lazy="row.item.documento" class="text-left" :placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </template>
                                                                <template #cell(opciones)="param">
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc==''" @click="quitarDocumento(param, 5)" v-show="datosIso9001.listaPuntosNorma[4].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Quitar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                    <b-button v-if="param.item.idAseguramientoCalidadDoc!=''" @click="eliminarDocumento(param, 5)" v-show="datosIso9001.listaPuntosNorma[4].listaDocumentos.length>1" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                    </b-button>
                                                                </template>
                                                            </b-table>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </b-col>
                                <b-col class="my-2 text-center" md="12">
                                    <b-button variant="dark" class=" mr-2" :to="{name: 'Aseguramiento de calidad'}">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </b-button>
                                    <b-button v-if="checkPermissions('007-9001-ASE','u')==1 && $route.params.id" :disabled="disabled" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                    <b-button v-if="checkPermissions('007-9001-ASE','c')==1 && !$route.params.id" :disabled="disabled" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </CCardBody>
                <CCardFooter>

                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalAgregarDocumento: false,

            cabeceraDocumentos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "codigoDocumento",
                    label: "Código documento",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "fechaPublicacion",
                    label: "Fecha publicación",
                    class: "text-center",
                },
                {
                    key: "responsable",
                    label: "Responsable",
                    class: "text-center",
                },
                {
                    key: "documento",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            comboTipo: [{
                    value: 1,
                    text: 'Proyecto'
                },
                {
                    value: 2,
                    text: 'Lote de producto'
                }
            ],
            comboProyecto: [],
            comboServicios: [],
            comboLoteProductos: [],
            comboEjercicioAnio: [],
            datosIso9001: {
                idAseguramientoCalidad: '',
                tipo: null,
                idProyectoLote: '',
                idServicio: '',
                anio: moment().format('YYYY'),
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                listaPuntosNorma: [{
                        idAseguramientoCalidadPunto: '',
                        idAseguramientoCalidad: '',
                        identificador: '1',
                        descripcion: '',
                        fechaTomaMuestra: moment().format('YYYY-MM-DD'),
                        fechaInforme: moment().format('YYYY-MM-DD'),
                        responsable: '',
                        listaDocumentos: [{
                            idAseguramientoCalidadDoc: '',
                            idAseguramientoCalidadPunto: '',
                            codigoDocumento: '',
                            nombre: '',
                            fechaPublicacion: moment().format('YYYY-MM-DD'),
                            responsable: '',
                            documento: null,
                            urlDocumento: '',
                            nombreDocumento: ''
                        }]
                    },
                    {
                        idAseguramientoCalidadPunto: '',
                        idAseguramientoCalidad: '',
                        identificador: '2',
                        descripcion: '',
                        fechaTomaMuestra: moment().format('YYYY-MM-DD'),
                        fechaInforme: moment().format('YYYY-MM-DD'),
                        responsable: '',
                        listaDocumentos: [{
                            idAseguramientoCalidadDoc: '',
                            idAseguramientoCalidadPunto: '',
                            codigoDocumento: '',
                            nombre: '',
                            fechaPublicacion: moment().format('YYYY-MM-DD'),
                            responsable: '',
                            documento: null,
                            urlDocumento: '',
                            nombreDocumento: ''
                        }]
                    },
                    {
                        idAseguramientoCalidadPunto: '',
                        idAseguramientoCalidad: '',
                        identificador: '3',
                        descripcion: '',
                        fechaTomaMuestra: moment().format('YYYY-MM-DD'),
                        fechaInforme: moment().format('YYYY-MM-DD'),
                        responsable: '',
                        listaDocumentos: [{
                            idAseguramientoCalidadDoc: '',
                            idAseguramientoCalidadPunto: '',
                            codigoDocumento: '',
                            nombre: '',
                            fechaPublicacion: moment().format('YYYY-MM-DD'),
                            responsable: '',
                            documento: null,
                            urlDocumento: '',
                            nombreDocumento: ''
                        }]
                    },
                    {
                        idAseguramientoCalidadPunto: '',
                        idAseguramientoCalidad: '',
                        identificador: '4',
                        descripcion: '',
                        fechaTomaMuestra: moment().format('YYYY-MM-DD'),
                        fechaInforme: moment().format('YYYY-MM-DD'),
                        responsable: '',
                        listaDocumentos: [{
                            idAseguramientoCalidadDoc: '',
                            idAseguramientoCalidadPunto: '',
                            codigoDocumento: '',
                            nombre: '',
                            fechaPublicacion: moment().format('YYYY-MM-DD'),
                            responsable: '',
                            documento: null,
                            urlDocumento: '',
                            nombreDocumento: ''
                        }]
                    },
                    {
                        idAseguramientoCalidadPunto: '',
                        idAseguramientoCalidad: '',
                        identificador: '5',
                        descripcion: '',
                        fechaTomaMuestra: moment().format('YYYY-MM-DD'),
                        fechaInforme: moment().format('YYYY-MM-DD'),
                        responsable: '',
                        listaDocumentos: [{
                            idAseguramientoCalidadDoc: '',
                            idAseguramientoCalidadPunto: '',
                            codigoDocumento: '',
                            nombre: '',
                            fechaPublicacion: moment().format('YYYY-MM-DD'),
                            responsable: '',
                            documento: null,
                            urlDocumento: '',
                            nombreDocumento: ''
                        }]
                    },
                ]
            },
            disabled: false,
            datosSession: {
                idCliente: ''
            },
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        blurLoteProducto() {
            this.computedForm.refs.lote_producto.validate();
        },
        blurServicio() {
            this.computedForm.refs.servicio.validate();
        },
        blurEjercicio() {
            this.computedForm.refs.ejercicio.validate();
        },
        descargarDocumento(url) {
            window.open(url)
        },
        cambiarCombo(){
            this.datosIso9001.idProyectoLote = '';
        },
        listarProyectos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-proyectos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProyecto = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarServicios() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-servicios-proyectos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboServicios = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarLoteProductos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-lote-productos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboLoteProductos = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarAseguramientoCalidadPuntos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-aseguramiento-calidad-puntos", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idAseguramientoCalidad: me.$route.params.id,
                        ejercicio: JSON.parse(localStorage.settings).yExcs
                    }
                })
                .then(function (response) {
                    me.datosIso9001.idAseguramientoCalidad = response.data[0].idAseguramientoCalidad;
                    me.datosIso9001.anio = response.data[0].anio;
                    me.datosIso9001.tipo = response.data[0].tipo;
                    me.datosIso9001.idProyectoLote = response.data[0].idProyectoLote;
                    me.datosIso9001.idServicio = response.data[0].idServicio;
                    me.datosIso9001.ejercicio = response.data[0].ejercicio;

                    for (const i in response.data) {
                        if (response.data[i].identificador == 1) {
                            me.datosIso9001.listaPuntosNorma[0].idAseguramientoCalidadPunto = response.data[i].idAseguramientoCalidadPunto;
                            me.datosIso9001.listaPuntosNorma[0].idAseguramientoCalidad = response.data[i].idAseguramientoCalidad;
                            me.datosIso9001.listaPuntosNorma[0].identificador = response.data[i].identificador;
                            me.datosIso9001.listaPuntosNorma[0].descripcion = response.data[i].descripcion;
                            me.datosIso9001.listaPuntosNorma[0].fechaTomaMuestra = response.data[i].fechaTomaMuestra;
                            me.datosIso9001.listaPuntosNorma[0].fechaInforme = response.data[i].fechaInforme;
                            me.datosIso9001.listaPuntosNorma[0].responsable = response.data[i].responsable;
                        }
                        if (response.data[i].identificador == 2) {
                            me.datosIso9001.listaPuntosNorma[1].idAseguramientoCalidadPunto = response.data[i].idAseguramientoCalidadPunto;
                            me.datosIso9001.listaPuntosNorma[1].idAseguramientoCalidad = response.data[i].idAseguramientoCalidad;
                            me.datosIso9001.listaPuntosNorma[1].identificador = response.data[i].identificador;
                            me.datosIso9001.listaPuntosNorma[1].descripcion = response.data[i].descripcion;
                            me.datosIso9001.listaPuntosNorma[1].fechaTomaMuestra = response.data[i].fechaTomaMuestra;
                            me.datosIso9001.listaPuntosNorma[1].fechaInforme = response.data[i].fechaInforme;
                            me.datosIso9001.listaPuntosNorma[1].responsable = response.data[i].responsable;
                        }
                        if (response.data[i].identificador == 3) {
                            me.datosIso9001.listaPuntosNorma[2].idAseguramientoCalidadPunto = response.data[i].idAseguramientoCalidadPunto;
                            me.datosIso9001.listaPuntosNorma[2].idAseguramientoCalidad = response.data[i].idAseguramientoCalidad;
                            me.datosIso9001.listaPuntosNorma[2].identificador = response.data[i].identificador;
                            me.datosIso9001.listaPuntosNorma[2].descripcion = response.data[i].descripcion;
                            me.datosIso9001.listaPuntosNorma[2].fechaTomaMuestra = response.data[i].fechaTomaMuestra;
                            me.datosIso9001.listaPuntosNorma[2].fechaInforme = response.data[i].fechaInforme;
                            me.datosIso9001.listaPuntosNorma[2].responsable = response.data[i].responsable;
                        }
                        if (response.data[i].identificador == 4) {
                            me.datosIso9001.listaPuntosNorma[3].idAseguramientoCalidadPunto = response.data[i].idAseguramientoCalidadPunto;
                            me.datosIso9001.listaPuntosNorma[3].idAseguramientoCalidad = response.data[i].idAseguramientoCalidad;
                            me.datosIso9001.listaPuntosNorma[3].identificador = response.data[i].identificador;
                            me.datosIso9001.listaPuntosNorma[3].descripcion = response.data[i].descripcion;
                            me.datosIso9001.listaPuntosNorma[3].fechaTomaMuestra = response.data[i].fechaTomaMuestra;
                            me.datosIso9001.listaPuntosNorma[3].fechaInforme = response.data[i].fechaInforme;
                            me.datosIso9001.listaPuntosNorma[3].responsable = response.data[i].responsable;
                        }
                        if (response.data[i].identificador == 5) {
                            me.datosIso9001.listaPuntosNorma[4].idAseguramientoCalidadPunto = response.data[i].idAseguramientoCalidadPunto;
                            me.datosIso9001.listaPuntosNorma[4].idAseguramientoCalidad = response.data[i].idAseguramientoCalidad;
                            me.datosIso9001.listaPuntosNorma[4].identificador = response.data[i].identificador;
                            me.datosIso9001.listaPuntosNorma[4].descripcion = response.data[i].descripcion;
                            me.datosIso9001.listaPuntosNorma[4].fechaTomaMuestra = response.data[i].fechaTomaMuestra;
                            me.datosIso9001.listaPuntosNorma[4].fechaInforme = response.data[i].fechaInforme;
                            me.datosIso9001.listaPuntosNorma[4].responsable = response.data[i].responsable;
                        }

                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarAseguramientoCalidadDocumentos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-aseguramiento-calidad-documentos", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idAseguramientoCalidad: me.$route.params.id,
                        ejercicio: JSON.parse(localStorage.settings).yExcs
                    }
                })
                .then(function (response) {

                    for (const i in response.data) {
                        if (response.data[i].identificador == 1) {
                            me.datosIso9001.listaPuntosNorma[0].listaDocumentos.push({
                                idAseguramientoCalidadDoc: response.data[i].idAseguramientoCalidadDoc,
                                idAseguramientoCalidadPunto: response.data[i].idAseguramientoCalidadPunto,
                                codigoDocumento: response.data[i].codigoDocumento,
                                nombre: response.data[i].nombre,
                                fechaPublicacion: response.data[i].fechaPublicacion,
                                responsable: response.data[i].responsable,
                                documento: null,
                                urlDocumento: response.data[i].urlDocumento,
                                nombreDocumento: response.data[i].nombreDocumento,
                            })
                        }
                        if (response.data[i].identificador == 2) {
                            me.datosIso9001.listaPuntosNorma[1].listaDocumentos.push({
                                idAseguramientoCalidadDoc: response.data[i].idAseguramientoCalidadDoc,
                                idAseguramientoCalidadPunto: response.data[i].idAseguramientoCalidadPunto,
                                codigoDocumento: response.data[i].codigoDocumento,
                                nombre: response.data[i].nombre,
                                fechaPublicacion: response.data[i].fechaPublicacion,
                                responsable: response.data[i].responsable,
                                documento: null,
                                urlDocumento: response.data[i].urlDocumento,
                                nombreDocumento: response.data[i].nombreDocumento,
                            })
                        }
                        if (response.data[i].identificador == 3) {
                            me.datosIso9001.listaPuntosNorma[2].listaDocumentos.push({
                                idAseguramientoCalidadDoc: response.data[i].idAseguramientoCalidadDoc,
                                idAseguramientoCalidadPunto: response.data[i].idAseguramientoCalidadPunto,
                                codigoDocumento: response.data[i].codigoDocumento,
                                nombre: response.data[i].nombre,
                                fechaPublicacion: response.data[i].fechaPublicacion,
                                responsable: response.data[i].responsable,
                                documento: null,
                                urlDocumento: response.data[i].urlDocumento,
                                nombreDocumento: response.data[i].nombreDocumento,
                            })
                        }
                        if (response.data[i].identificador == 4) {
                            me.datosIso9001.listaPuntosNorma[3].listaDocumentos.push({
                                idAseguramientoCalidadDoc: response.data[i].idAseguramientoCalidadDoc,
                                idAseguramientoCalidadPunto: response.data[i].idAseguramientoCalidadPunto,
                                codigoDocumento: response.data[i].codigoDocumento,
                                nombre: response.data[i].nombre,
                                fechaPublicacion: response.data[i].fechaPublicacion,
                                responsable: response.data[i].responsable,
                                documento: null,
                                urlDocumento: response.data[i].urlDocumento,
                                nombreDocumento: response.data[i].nombreDocumento,
                            })
                        }
                        if (response.data[i].identificador == 5) {
                            me.datosIso9001.listaPuntosNorma[4].listaDocumentos.push({
                                idAseguramientoCalidadDoc: response.data[i].idAseguramientoCalidadDoc,
                                idAseguramientoCalidadPunto: response.data[i].idAseguramientoCalidadPunto,
                                codigoDocumento: response.data[i].codigoDocumento,
                                nombre: response.data[i].nombre,
                                fechaPublicacion: response.data[i].fechaPublicacion,
                                responsable: response.data[i].responsable,
                                documento: null,
                                urlDocumento: response.data[i].urlDocumento,
                                nombreDocumento: response.data[i].nombreDocumento,
                            })
                        }
                    }

                    if (me.datosIso9001.listaPuntosNorma[0].listaDocumentos.length > 1) {
                        me.datosIso9001.listaPuntosNorma[0].listaDocumentos.splice(0, 1);
                    }
                    if (me.datosIso9001.listaPuntosNorma[1].listaDocumentos.length > 1) {
                        me.datosIso9001.listaPuntosNorma[1].listaDocumentos.splice(0, 1);
                    }
                    if (me.datosIso9001.listaPuntosNorma[2].listaDocumentos.length > 1) {
                        me.datosIso9001.listaPuntosNorma[2].listaDocumentos.splice(0, 1);
                    }
                    if (me.datosIso9001.listaPuntosNorma[3].listaDocumentos.length > 1) {
                        me.datosIso9001.listaPuntosNorma[3].listaDocumentos.splice(0, 1);
                    }
                    if (me.datosIso9001.listaPuntosNorma[4].listaDocumentos.length > 1) {
                        me.datosIso9001.listaPuntosNorma[4].listaDocumentos.splice(0, 1);
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarIso9001() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            let idIso = 0;

            for (const i in me.datosIso9001.listaPuntosNorma) {
                for (const j in me.datosIso9001.listaPuntosNorma[i].listaDocumentos) {
                    if (me.datosIso9001.listaPuntosNorma[i].listaDocumentos[j].idAseguramientoCalidadDoc == '' && me.datosIso9001.listaPuntosNorma[i].listaDocumentos[j].documento != null) {
                        idIso = idIso - 1;
                        formData.append("file&&" + idIso, me.datosIso9001.listaPuntosNorma[i].listaDocumentos[j].documento);
                    } else {
                        formData.append("file&&" + me.datosIso9001.listaPuntosNorma[i].listaDocumentos[j].idAseguramientoCalidadDoc, me.datosIso9001.listaPuntosNorma[i].listaDocumentos[j].documento);
                    }
                }

            }

            formData.append("datosIso9001", JSON.stringify(me.datosIso9001));
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'aseguramiento-calidad');

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-aseguramiento-calidad",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.$router.push({
                        name: 'Aseguramiento de calidad',
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        agregarEjerciciosAnios() {
            let anio = parseInt(moment().format('YYYY'));
            for (let i = anio - 10; i < anio + 10; i++)
                this.comboEjercicioAnio.push({
                    descripcion: i
                })
        },
        agregarDocumento(cond) {
            let me = this;
            if (cond == 1) {
                me.datosIso9001.listaPuntosNorma[0].listaDocumentos.push({
                    idAseguramientoCalidadDoc: '',
                    idAseguramientoCalidadPunto: '',
                    codigoDocumento: '',
                    nombre: '',
                    fechaPublicacion: moment().format('YYYY-MM-DD'),
                    responsable: '',
                    documento: null,
                    urlDocumento: '',
                    nombreDocumento: ''
                })
            } else if (cond == 2) {
                me.datosIso9001.listaPuntosNorma[1].listaDocumentos.push({
                    idAseguramientoCalidadDoc: '',
                    idAseguramientoCalidadPunto: '',
                    codigoDocumento: '',
                    nombre: '',
                    fechaPublicacion: moment().format('YYYY-MM-DD'),
                    responsable: '',
                    documento: null,
                    urlDocumento: '',
                    nombreDocumento: ''
                })
            } else if (cond == 3) {
                me.datosIso9001.listaPuntosNorma[2].listaDocumentos.push({
                    idAseguramientoCalidadDoc: '',
                    idAseguramientoCalidadPunto: '',
                    codigoDocumento: '',
                    nombre: '',
                    fechaPublicacion: moment().format('YYYY-MM-DD'),
                    responsable: '',
                    documento: null,
                    urlDocumento: '',
                    nombreDocumento: ''
                })
            } else if (cond == 4) {
                me.datosIso9001.listaPuntosNorma[3].listaDocumentos.push({
                    idAseguramientoCalidadDoc: '',
                    idAseguramientoCalidadPunto: '',
                    codigoDocumento: '',
                    nombre: '',
                    fechaPublicacion: moment().format('YYYY-MM-DD'),
                    responsable: '',
                    documento: null,
                    urlDocumento: '',
                    nombreDocumento: ''
                })
            } else if (cond == 5) {
                me.datosIso9001.listaPuntosNorma[4].listaDocumentos.push({
                    idAseguramientoCalidadDoc: '',
                    idAseguramientoCalidadPunto: '',
                    codigoDocumento: '',
                    nombre: '',
                    fechaPublicacion: moment().format('YYYY-MM-DD'),
                    responsable: '',
                    documento: null,
                    urlDocumento: '',
                    nombreDocumento: ''
                })
            }

            me.modalAgregarDocumento = false;
        },
        quitarDocumento(param, cond) {
            let me = this;
            if (cond == 1) {
                me.datosIso9001.listaPuntosNorma[0].listaDocumentos.splice(param.index, 1);
            } else if (cond == 2) {
                me.datosIso9001.listaPuntosNorma[1].listaDocumentos.splice(param.index, 1);
            } else if (cond == 3) {
                me.datosIso9001.listaPuntosNorma[2].listaDocumentos.splice(param.index, 1);
            } else if (cond == 4) {
                me.datosIso9001.listaPuntosNorma[3].listaDocumentos.splice(param.index, 1);
            } else if (cond == 5) {
                me.datosIso9001.listaPuntosNorma[4].listaDocumentos.splice(param.index, 1);
            }
        },
        eliminarDocumento(param, cond) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el documento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-documento-calidad", {
                            idAseguramientoCalidadDoc: param.item.idAseguramientoCalidadDoc,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)

                            if (cond == 1) {
                                me.datosIso9001.listaPuntosNorma[0].listaDocumentos.splice(param.index, 1);
                            } else if (cond == 2) {
                                me.datosIso9001.listaPuntosNorma[1].listaDocumentos.splice(param.index, 1);
                            } else if (cond == 3) {
                                me.datosIso9001.listaPuntosNorma[2].listaDocumentos.splice(param.index, 1);
                            } else if (cond == 4) {
                                me.datosIso9001.listaPuntosNorma[3].listaDocumentos.splice(param.index, 1);
                            } else if (cond == 5) {
                                me.datosIso9001.listaPuntosNorma[4].listaDocumentos.splice(param.index, 1);
                            }
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.agregarEjerciciosAnios();
            this.listarProyectos();
            this.listarServicios();
            this.listarLoteProductos();
            if (this.$route.params.id != null) {
                this.listarAseguramientoCalidadPuntos();
                this.listarAseguramientoCalidadDocumentos();
            }
        }
    }

}
</script>
